/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import HTMLParser from '../components/html-parser';

export default function Frontpage({ data, pageContext }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const { pages } = pageContext;

  const folderArr = frontmatter.filePath.split('/');
  const fileDirectory = folderArr.splice(0, folderArr.length - 1).join('/');

  return (
    <div>
      <Helmet>
        <title>{frontmatter.title || frontmatter.headline}</title>

        <meta property="og:description" content={frontmatter.description} />

        <meta name="description" content={frontmatter.description} />
      </Helmet>
      <h1>{frontmatter.headline}</h1>

      <HTMLParser html={html} pages={pages} fileDirectory={fileDirectory} />
    </div>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      tableOfContents(absolute: false)

      frontmatter {
        path
        headline
        title
        level
        date
        description
        filePath
      }
    }
  }
`;
